<!-- Cancel dialog -->
<app-dialog data-test="cancel-dialog">
  <div class="flex flex-col py-8">
    <p class="mb-4 text-center">{{'photoshoot.cancel-description' | translate}}</p>
    <div class="flex flex-col gap-y-2">
      <button appRipple data-test="abort-button" mat-flat-button color="accent" class="small" (debounceClick)="onCloseDialog(true)">{{'photoshoot.restart' | translate}}</button>
      <button appRipple data-test="continue-button" mat-button color="primary" class="small" (debounceClick)="onCloseDialog(false)">{{'photoshoot.continue' | translate}}</button>
    </div>
  </div>
</app-dialog>
